.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  /* background: #ededee !important; */
  background: linear-gradient(
    180deg,
    rgb(255, 192, 192) 0%,
    rgb(255, 205, 205) 15%,
    rgb(255, 218, 218) 30%,
    rgb(255, 228, 228) 50%,
    rgb(255, 238, 238) 70%,
    rgb(255, 245, 245) 85%,
    rgb(255, 248, 248) 100%
  ) !important;
  /* 以下屬性 確保漸層效果覆蓋整個頁面 */
  min-height: 100vh;
  margin: 0;
  background-attachment: fixed;
}
.leftBG {
  background: linear-gradient(
    180deg,
    #fdd9d8 0%,
    #fde1e0 40%,
    #fee5e4 60%,
    #feeded 80%,
    #fff5f5 100%
  );
}
.menu-box-shadow {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
    rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}
.box-shadow-left {
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 4px -1px,
    rgba(0, 0, 0, 0.14) 2px 4px 5px 0px, rgba(0, 0, 0, 0.12) 2px 1px 10px 0px;
}
.box-shadow-right {
  box-shadow: rgba(0, 0, 0, 0.2) -2px 2px 4px -1px,
    rgba(0, 0, 0, 0.14) -2px 4px 5px 0px, rgba(0, 0, 0, 0.12) -2px 1px 10px 0px;
}

.menu-font {
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
}
.blue-button {
  line-height: 1.75;
}
.hover-bg-transition {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.dropdown-menu {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4375em;
  color: #828282;
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid #fff;
  background: #fff;
  position: relative;
  border-radius: 12px !important;
}
:where(.css-xu9wm8).ant-dropdown .ant-dropdown-menu,
:where(.css-xu9wm8).ant-dropdown-menu-submenu .ant-dropdown-menu {
  background: #fff !important;
}
:where(.css-dev-only-do-not-override-xu9wm8).ant-dropdown .ant-dropdown-menu,
:where(.css-dev-only-do-not-override-xu9wm8).ant-dropdown-menu-submenu
  .ant-dropdown-menu {
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #414141 !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  background: #fff !important;
  overflow: hidden auto;
  min-width: 16px;
  min-height: 16px;
  outline: 0px;
}

.ant-dropdown-menu-item {
  padding: 10px 12px !important;
  color: #414141 !important;
}
.ant-dropdown-menu-item:hover {
  color: rgb(251, 129, 17) !important;
  background-color: rgba(251, 129, 17, 0.08) !important;
}
.black-mask {
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}
.menu-left {
  transform: none;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  width: 275px;
  background: #f4f4f4;
  border-radius: 0 16px 16px 0;
  position: fixed;
  top: 0;
  outline: 0;
  left: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.menu-right {
  transform: none;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  width: 275px;
  background: #fef0ef;
  border-radius: 16px 0 0 16px;
  position: fixed;
  top: 0;
  outline: 0;
  right: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.language-select-mobile {
  position: relative;
}

.language-select-mobile select {
  position: absolute;
  top: 0;
  transform: translateY(-100%);
}
.logo-drop-shadow {
  filter: drop-shadow(1px 1px 0px #ad8491);
}
.carousel-bg {
  background: linear-gradient(
    0deg,
    rgb(255 215 178) 88%,
    rgb(237 237 237) 88%,
    rgb(237 237 237) 60%
  );
}
.play-button {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.928571rem;
  line-height: 1.75;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 700;
  border-radius: 12px;
  text-transform: capitalize;
  border: 1px solid rgb(213, 210, 223);
  color: rgb(251, 129, 17);
  padding: 10px 20px;
}
.play-button:hover {
  background: rgba(249, 249, 249, 0.067);
}
.more-button {
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.75;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: #ff8087;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 1px -2px, rgba(0, 0, 0, 0.14) 0 2px 2px 0,
    rgba(0, 0, 0, 0.12) 0 1px 5px 0;
  font-weight: 700;
  border-radius: 12px;
  padding: 10px 20px;
  text-transform: capitalize;
}
.more-button:hover {
  text-decoration: none;
  background-color: #e33f48 !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px -1px, rgba(0, 0, 0, 0.14) 0 4px 5px 0,
    rgba(0, 0, 0, 0.12) 0 1px 10px 0;
}
.learn-button {
  border-radius: 64px;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.928571rem;
  line-height: 1.75;
  width: max-content;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 2px solid rgba(251, 129, 17, 0.5);
  color: rgb(251, 129, 17);
  font-weight: 700;
  text-transform: capitalize;
  padding: 6px 16px;
}
.learn-button:hover {
  border-width: 2px;
  background: rgba(249, 249, 249, 0.067);
  border: 1px solid rgb(251, 129, 17);
}
.gameSlide-container {
  position: relative;
}
.rc-virtual-list-holder {
  max-height: max-content !important;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(41 33 25 / 76%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.overlay:hover {
  opacity: 1;
}

.overlay-content {
  color: white;
}

.overlay-content p {
  font-size: 18px;
  margin-bottom: 10px;
}

.overlay-content button {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.overlay-content button:hover {
  background-color: white;
  color: black;
}
.splide-arrow-button {
  border: 2px solid #726190;
  cursor: pointer;
}
.splide-arrow-button:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
.feature-icon {
  border-radius: 16px;
  background: linear-gradient(
    295.7deg,
    rgb(251 129 17) 49.91%,
    rgba(42, 36, 58, 0) 97.93%
  );
  transition: rotate 150ms ease-in-out 0s;
}
.feature-icon:hover {
  rotate: -20deg;
}
.feature-icon svg {
  opacity: 1;
}
.feature-icon:hover svg {
  opacity: 0;
}
.feature-icon img {
  transform: translate(16px, 1px);
  rotate: 20deg;
  transition: opacity 150ms ease-in-out 0s;
  opacity: 0;
}
.feature-icon:hover img {
  opacity: 1;
}
.view-all-button {
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: "Nunito Sans", sans-serif;
  line-height: 1.75;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 12px;
  text-transform: capitalize;
  font-size: 12px;
  color: rgb(139, 133, 159);
  font-weight: 700;
  padding: 7.5px 16px;
  border: 1px solid #fff;
}
.view-all-button:hover {
  border: 1px solid rgb(251 129 17);
  background: rgba(249, 249, 249, 0.067);
}
.footer-bg {
  background: linear-gradient(rgb(255 231 210) 0%, rgba(29, 25, 41, 0) 100%);
}
.footer-icon {
  border-radius: 16px;
  background: linear-gradient(
    295.7deg,
    rgb(207 207 207) 49.91%,
    rgba(42, 36, 58, 0) 97.93%
  );
}
.community-icon:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
.bg-linear {
  background: linear-gradient(
    333deg,
    rgb(255 216 191) 61.32%,
    rgba(42, 36, 58, 0) 88.89%
  );
}
.levelUp-bg-linear {
  background: linear-gradient(rgba(42, 36, 58, 0) 0%, rgb(255 216 191) 100%);
}
.move-up-down {
  /* rotate: -34deg; */
  animation: 1s ease-in-out 0s infinite normal none running bop;
}
.li-dot {
  list-style: disc;
}
.input-border {
  border: 1px solid #ffc2b0;
}
.ant-input {
  background-color: transparent !important;
  color: #000;
}
.ant-input {
  &::placeholder {
    color: #5c5474;
  }
}
.service-icon {
  border-radius: 16px;
  background: linear-gradient(
    295.7deg,
    rgb(255 255 255) 49.91%,
    rgba(42, 36, 58, 0) 97.93%
  );
}
@keyframes bop {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
.pc_show {
  display: block;
}
.mobile_show {
  display: none;
}
@media (max-width: 768px) {
  .pc_show {
    display: none;
  }
  .mobile_show {
    display: block;
  }
}

.gradient-button {
  background: rgb(233, 156, 253);
  background: linear-gradient(
    135deg,
    rgba(233, 156, 253, 1) 0%,
    rgba(248, 169, 167, 1) 100%
  );
  transition: all 0.3s ease;
}

.gradient-button:hover {
  background: linear-gradient(
    135deg,
    rgba(248, 169, 167, 1) 0%,
    rgba(233, 156, 253, 1) 100%
  );
}
.custom-scrollbar {
  scrollbar-width: thin !important;
  scrollbar-color: #fed4d3 transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #fed4d3 !important;
  border-radius: 10px !important;
}
.ant-input::placeholder {
  color: white !important; /* mobile用白色 */
}

@media (min-width: 768px) {
  .ant-input::placeholder {
    color: #828282 !important; /* pc用灰色 */
  }
}

@font-face {
  font-family: "CustomFont";
  src: url("../src/fonts/hyyakuheiw.ttf") format("truetype");
}
@font-face {
  font-family: "CustomFont2";
  src: url("../src/fonts/PingFang Bold.ttf") format("truetype");
}
